<template>
  <b-navbar fixed="top" toggleable="lg">
    <b-container class="position-relative">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" class="justify-content-between row" is-nav>
        <slot name="start">
          <b-link
            class="d-flex align-items-center a-back-button"
            @click="goBack()"
          >
            <b-icon icon="chevron-left"></b-icon>
          </b-link>
        </slot>
        <slot name="center"></slot>

        <b-navbar-nav>
          <slot name="end"></slot>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: 'Header',

  props: {
    backPath: { default: '/' },
    backClick: { required: false }
  },
  
  computed: {
    showLogo() {
      return !this.$route.params.id
    },
    showLogout() {
      return !!this.$route.path.match(/^\/testy.*/)
    }
  },

  methods: { 
    goBack() {
      this.backClick ? this.backClick() : this.$router.push(this.backPath)
    }
  }
}
</script>

<style scoped lang="sass">
.navbar
  padding: 1.5rem 1rem

#nav-collapse
  padding: 0 1rem
</style>